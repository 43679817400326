.footer {
  background-color: $dark-gray;
  padding-top: 8.5rem;

  h3 {
    font-family: $secondary-font;
    color: $light-gray;
    font-size: 2.375rem;
  }

  p, li {
    color: $white;
    line-height: 1.75rem;
    font-size: 1rem;
  }

  .footer-top {
    margin-bottom: 11rem;
  }

  .footer-menu {
    h3 {
      font-size: 2rem;
      color: $white;
      font-family: $tertiary-font;
    }
    li {
      list-style: none;
    }
  }

  .line {
    width: 100%;
    height: 1rem;
    border-bottom: solid 1px $mid-gray;
    margin-bottom: 2rem;
  }

  .copyright {
    font-size: 0.875rem;
    margin-bottom: 3rem;
  }

  a {
    text-decoration: none;
  }
}
