/**
  FONTS
*/

@font-face {
  font-family: 'gilroy-bold';
  src: url('../assets/fonts/gilroy-bold/Gilroy-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/gilroy-bold/Gilroy-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/gilroy-bold/Gilroy-Bold.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/gilroy-bold/Gilroy-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/gilroy-bold/Gilroy-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'gilroy-extrabold';
  src: url('../assets/fonts/gilroy-extrabold/Gilroy-ExtraBold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/gilroy-extrabold/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/gilroy-extrabold/Gilroy-ExtraBold.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/gilroy-extrabold/Gilroy-ExtraBold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/gilroy-extrabold/Gilroy-ExtraBold.svg#svgFontName') format('svg'); /* Legacy iOS */
}


p {
  font-size: 1.5rem;
}

h1 {
  font-size: 3.75rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.75rem;
}

li {
  font-size: 0.875rem;
}