.biography {
  padding: 0 2.5rem;
  p {
    font-family: $primary-font;
  }

  .gap {
    height: 3.85rem;
  }

  .contact-info {
    margin-bottom: 3.125rem;

    p, a {
      display: block;
      text-decoration: none;
      font-size: 1.5rem;
      margin: 0;
      line-height: 3rem;
    }

    .title {
      font-weight: 500;
    }

    .email {
      color: $light-blue;
    }

    .phone {
      color: $black;
    }
  }

  .intervisie-section {
    margin: 3.5rem auto;
    text-align: center;
  }

  .intervisie-link {
    color: $light-blue;
  }

  @include forMobile {
    .personal-hj {
      margin-top: 2rem;
    }

    img {
      width: 85vw;
    }
  }

  @include mdAndAbove {
    .large-image {
      width: 100%;
    }
    .small-image {
      width: 100%;
    }
  }  

  @include xlOnly {
    .personal-hj {
      padding-left: 3rem;
    }

    .personal-axel {
      padding-right: 3rem;
    }
  }
}