.terms {
  width: 90vw;
  
  .terms-section {
    margin: auto;
  }

  .button {
    padding: 1rem 2rem;
    align-items: center;
    color: $white;
    background-color: $dark-blue;
    border: 2px $border-blue solid;
    border-radius: 1.5rem;

    a {
      text-decoration: none;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  @include forMobile() {
    h1 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1.25rem;
    }

    .text-section {
      padding: 0;
    }
  }
}