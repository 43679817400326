@import '../colors';

.banner {
  background-color: $dark-gray;
  position: sticky;
  top: 0px;
  z-index: 5;

  .col {
    padding-top: 21px;
  }

  p {
    font-size: 1rem;
    text-align: center;
    color: $white;
    font-family: $tertiary-font;
  }

  @include forMobile() {
    display: none;
  }
}