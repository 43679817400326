.hero-container{
  width: 90vw;
 
  .hero {
    width: 100%;
    background-image: url(../../assets/images/hero_image.png);
    background-size: cover;

    @include xlOnly {
      height: 75vh;
    }


    @media (min-height: 1080px) {
      height: 50vh;
    }

  }

  .hero-content {
    padding: 3rem 1rem;
    @include mdAndAbove {
      padding-top: 11rem;
    }

    h1 {
      color: $white;
      font-family: $secondary-font;
      margin-bottom: 0;
      @include forMobile {
        font-size: 3.5rem;
      }
    }

    h3 {
      color: $white;
      font-family: $primary-font;
      font-weight: 400;
      margin-bottom: 1;
    }

    .button {
      background-color: $dark-blue;
      border: 2px $border-blue solid;
      border-radius: 1.5rem;
    }
  }
}

.header {
  padding: 2rem 0;
  width: 100%;

  .responsive-image {
    width: 100%;
    @include forMobile {
      width: 100% !important; 
    }
  }
}